import React from 'react';
import '../../assets/css/index.css';
import { Button, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../../components/SEO';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import PlantRoomExamples from '../../components/PlantRoomExamples';
import ContactForm from "../../components/ContactForm";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title="What is a Plant Room?"
        description="Find out what commercial boiler plant rooms are and the benefits of having one on your premises in this guide."
        keywords="commercial boiler maintenance, boiler maintenance"
      />
      <div className='grey-segment'>
      <Row>
        <Col>
        <h1 className='heading-color'>
          What is a Plant Room? Purpose, Components and Benefits 
          </h1>
          <p>A plant room is a crucial part of any large commercial building or facility. It serves as the <strong>central hub for essential equipment and machinery</strong> that’s responsible for keeping the building services running, such as <strong>heating, electricity, water supply, ventilation</strong> and more. </p>
          <Img
            fluid={data.gasboilerheatinginstallationsgen.childImageSharp.fluid}
            alt="Plant engineer working"
            className="mx-auto d-block img-fluid my-4"
          />
          <p>You may have heard them referred to as <strong>boiler plant rooms</strong> or simply <strong>boiler rooms</strong> - these are plant rooms that just contain equipment to provide <strong>heating and hot water.</strong></p>
          <p>In this article, we’ll delve into the <strong>purpose of a plant room,</strong> explore the <strong>components</strong> typically found in a plant room, and highlight the <strong>benefits</strong> of a well-maintained plant room, as well as answer some of the frequently asked questions surrounding plant rooms.</p>
          <ul>
            <li><a href="#the-purpose-of-a-plant-room">The purpose of a plant room</a></li>
            <li><a href="#who-needs-a-plant-room">Who needs a plant room</a></li>
            <li><a href="#components-found-in-a-plant-room">Components found in a plant room</a></li>
            <li><a href="#benefits-of-a-well-maintained-plant-room">Benefits of a well-maintained plant room</a></li>
            <li><a href="#how-big-should-a-plant-room-be">How big should a plant room be</a></li>
            <li><a href="#where-should-a-plant-room-be">Where should a plant room be</a></li>
            <li><a href="#does-a-plant-room-require-ventilation">Does a plant room require ventilation</a></li>
          </ul>
        </Col>
      </Row>
      </div>

      <div className='white-segment'>
      <Row>
        <Col>

          <h2 id="the-purpose-of-a-plant-room" className='heading-color'>The Purpose of a Plant Room</h2>
          <p>
          The primary purpose of a plant room is to house and protect the equipment necessary to operate the critical systems within a building. These systems commonly include <strong>heating, ventilation,</strong> <Link to='commercial-air-conditioning'> conditioning (HVAC)</Link>, <strong>electrics, plumbing, fire suppression</strong> and <strong>lighting.</strong>
          </p>
          <p>A plant room also helps to <strong>minimise noise pollution</strong> within the premises. By enclosing the equipment in a designated space, the noise generated by these systems is contained, preventing disruptions to occupants in the surrounding areas. This is particularly important in flats and office spaces, where tenants won’t want to be disturbed by noisy boilers or ventilation systems.           </p>
          <h2 id="who-needs-a-plant-room" className='heading-color'>Who needs a plant room?</h2>
          <p><strong>Any large building supplying lots of rooms with various services,</strong> such as heating, hot water and ventilation, needs a plant room. Most <strong>commercial buildings</strong> should have a plant room, such as flats, hotels, office blocks and <Link to='commercial-swimming-pool-boilers'>swimming pools.</Link> </p>
          <p>Unlike domestic properties, there are usually lots of different systems providing different utilities to various areas around the building, so keeping everything in one area makes it <strong>easy to access each system</strong> and look after it properly. </p>
          <h2 id="components-found-in-a-plant-room" className='heading-color'>
          Components found in a plant room
          </h2>
          <p>
          A well-designed plant room contains all the components that enable the smooth functioning of various building systems. Some of the typical components found in a plant room include:
          </p>
          <ul>
            <li><strong>Boilers:</strong> These are used to generate heating and hot water. They are often a critical component in buildings that require a centralised heating system. Many large commercial buildings will have a series of <Link to='commercial-boiler-installations'>boilers</Link> to ensure they can provide enough heating and hot water.</li>
            <li><strong>Chillers:</strong> Chillers are responsible for cooling the air or water used in an HVAC system. They are crucial for maintaining comfortable indoor temperatures.</li>
            <li><strong>Pumps:</strong> Pumps are used to circulate fluids throughout the different systems, such as water and refrigerants, ensuring proper distribution and circulation.</li>
            <li><strong>Air Handling Units (AHUs):</strong> AHUs are responsible for conditioning and distributing air throughout the building. They regulate temperature, humidity and air quality.</li>
            <li><strong>Electrical Distribution Boards:</strong> These boards serve as a central point for electrical distribution within the building. They provide power to various electrical systems and equipment.</li>
            <li><strong>Control Systems:</strong> A plant room often includes sophisticated control systems that enable operators to monitor and regulate the functioning of different systems. These systems help maintain optimal performance and energy efficiency.</li>
            <li><strong>Fire Suppression Systems:</strong> Plant rooms may incorporate fire suppression systems, such as sprinklers or gas suppression systems, to quickly mitigate potential fire hazards within the building.</li>
            <li><strong>Ventilation Systems:</strong> Ventilation systems ensure a constant supply of fresh air while removing stale air, odours and contaminants from the building.</li>
            <li><strong>Water Treatment Equipment:</strong> In buildings with water-based systems, plant rooms often house water treatment equipment such as filters, softeners and chemical dosing systems to maintain water quality and prevent corrosion.</li>
            <li><strong>Emergency Power Systems:</strong> Some plant rooms may include backup power generators to ensure uninterrupted power supply during outages.</li>
          </ul>

          <h2 id="benefits-of-a-well-maintained-plant-room" className='heading-color'>
          Benefits of a well-maintained plant room
          </h2>
          <Img
            fluid={data.maintenanceman.childImageSharp.fluid}
            alt="Plant engineer working"
            className="mx-auto d-block img-fluid my-4"
          />
          <p>
          A well-maintained plant room brings several benefits to a building or facility:
          </p>
          <ol>
            <li><strong>Optimal Performance:</strong> Regular maintenance ensures that all the components within the plant room are in good working condition. This results in improved performance and energy efficiency, reducing operational costs.</li>
            <li><strong>Improved Lifespan:</strong> Proper maintenance and servicing prolong the lifespan of equipment, reducing the need for premature replacements and associated costs.</li>
            <li><strong>Enhanced Safety:</strong> Well-maintained plant rooms adhere to safety standards, reducing the risk of accidents or equipment failures that could compromise the safety of tenants, customers, or visitors.</li>
            <li><strong>Improved Indoor Comfort:</strong> A well-functioning plant room ensures consistent temperature control, optimal air quality, and efficient distribution of heating and cooling throughout the building, enhancing occupant comfort.</li>
            <li><strong>Reduced Downtime:</strong> Regular maintenance and prompt repairs minimize unexpected breakdowns, reducing downtime and ensuring uninterrupted operation of critical systems.</li>
            <li><strong>Environmental Sustainability:</strong> A well-maintained plant room promotes energy efficiency, leading to a lower carbon footprint and reduced environmental impact.</li>
          </ol>
          </Col>
          </Row>
      </div>

    <div className='grey-segment'>
      <Row>
        <Col>

          <h3 id="how-big-should-a-plant-room-be" className='heading-color'>How big should a plant room be?</h3>
          <p>A plant room should be proportionate to the size of a building, so there isn’t one answer to how big a plant room should be. If you have a particularly large commercial building that spans many floors, you may need more than one plant room, each with its own function.</p>
          <p>You may have one plant room that acts as a boiler room, then another as a battery or ventilation room, for example. </p>
          <h3 id="where-should-a-plant-room-be"className='heading-color'>Where should a plant room be?</h3>
          <p>It’s a good idea to place your plant room <strong>centrally within your building</strong> if possible. This prevents you from needing lots of pipework that spans from one end of the building to another. </p>
          <p>However, it’s not always possible to have a central plant room, especially if your building is a residence or in a place that requires a level of quiet as the machinery inside can be quite noisy.</p>
          <p>Ideally, a plant room should be placed somewhere where there <strong>won’t be lots of foot traffic</strong> and <strong>can only be accessed by authorised people.</strong> They’re often put in basements, but this can cause problems with access when you need new boilers or other equipment. </p>
          <p>There may not be a perfect place for your plant room, but a good engineer will be able to help you <strong>locate the best spot for it and design one that works for you.</strong> At BBIS Commercial Heating, we’ve got years of experience in designing and fitting plant rooms for a range of commercial premises. </p>
          <h3 id="does-a-plant-room-require-ventilation"className='heading-color'>Does a plant room require ventilation?</h3>
          <p>Yes, a plant room does require ventilation. Most of the machinery within a plant room will generate heat, so it needs somewhere to escape to prevent the room from becoming too hot and damaging the equipment.</p>
          <p>If you’ve got gas boilers in your plant room, it’s a legal requirement that your plant room is ventilated by a flue and fresh air intake to ensure the gases are removed from the air. Without these measures, there’s a risk of carbon monoxide poisoning to anyone within the building, which can be fatal if it goes unnoticed.</p>
          <p>A plant room plays a critical role in maintaining the functionality and efficiency of various building systems. Housing and protecting essential components ensures optimal performance, improved safety and enhanced comfort for occupants. Regular maintenance and proper servicing of a plant room are essential for extending its lifespan and reaping the numerous benefits it offers to a building or facility.</p>
          <p>BBIS are experts in installing and maintaining plant rooms. If you’re looking for more information or would like a qualified and reputable engineer to help you with your plant room, then give us a call.</p>
          </Col>
      </Row>
      </div>
      <div className='white-segment'>
      <Row>
        <Col md>
        <PlantRoomExamples />
        </Col>
        <Col md>
        <h3 className='heading-color'>Contact BBIS Heating</h3>
        <ContactForm></ContactForm>
        </Col>
        </Row>
        </div>
          </Layout>
  );
};

export const query = graphql`
  query WhatIsAPlantRoomImages {
    gasboilerheatinginstallationsgen: file(
      relativePath: { eq: "Projects/burlingtonclose-4.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1137) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    maintenanceman: file(
      relativePath: { eq: "Hero/bbis-commercial-boiler-repair.jpeg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1137) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
